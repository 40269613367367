import React from 'react';

function Fline() {
  return (
    <div>
      <svg viewBox="0 0 800 395" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M779.5 1.5C817.333 77.5 817.5 230 515.5 232C418 232 22.5 181.5 2.5 394.5" stroke="white" strokeWidth="5" />
      </svg>

    </div>
  );
}

export default Fline;
