import React from 'react';

function Sline() {
  return (
    <div>
      <svg viewBox="0 0 23 1328" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 1L20 1327.5" stroke="white" strokeWidth="5" />
      </svg>

    </div>
  );
}

export default Sline;
