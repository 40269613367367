import './css/general-css/app.css';
import React from 'react';
import AnimatedRoutes from './components/general-componets/AnimatedRoutes';

function App() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div>

      <AnimatedRoutes />

    </div>

  );
}

export default App;
